import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Landing from './pages/Landing';
import CursoList from './pages/CursoList';
import CursoForm from './pages/CursoForm';
import CursoAtualizarForm from './pages/CursoAtualizarForm';

function Routes(){
    return(
        <BrowserRouter>
            <Route path="/" exact component={ Landing }/>
            <Route path="/lista-cursos" component={ CursoList }/>
            <Route path="/cadastro" component={ CursoForm }/>
            <Route path="/cursos/:id" component={CursoAtualizarForm}/>
        </BrowserRouter>
    );
}

export default Routes;