import React, {useState, useEffect, FormEvent} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import PageHeader from '../../components/PageReader';
import Input from '../../components/Input';
//import Textarea from '../../components/Textarea';

import warningIcon from '../../assets/images/icons/warning.svg';

import api from '../../services/api';

import './styles.css';

interface CursoParams {
    id: string;
}

function CursoAtualizarForm(){
    const history = useHistory();

    const params = useParams<CursoParams>();

    const [codigo, setCodigo] = useState('');
    const [nome, setNome] = useState('');
    const [natureza_programacao, setNatureza_programacao] = useState('');
    const [tipo_programacao, setTipo_programacao] = useState('');
    const [objetivo, setObjetivo] = useState('');
    const [conteudo_programatico, setConteudo_programatico] = useState('');
    const [area_ocupacional, setArea_ocupacional] = useState('');
    const [linha_acao, setLinha_acao] = useState('');
    const [atividade_cbo, setAtividade_cbo] = useState('');
    const [atividades_cbo, setAtividades_cbo] = useState('');
    const [areas_atividade, setAreas_atividade] = useState('');
    const [vagas_minimo, setVagas_minimo] = useState('');
    const [vagas_maximo, setVagas_maximo] = useState('');

    useEffect(() => {
        api.get(`cursos/${params.id}`).then((response) => {
            console.log(response.data.data);
            setCodigo(response.data.data.codigo);
            setNome(response.data.data.nome);
            setNatureza_programacao(response.data.data.natureza_programacao);
            setTipo_programacao(response.data.data.tipo_programacao);
            setObjetivo(response.data.data.objetivo);
            setConteudo_programatico(response.data.data.conteudo_programatico);
            setArea_ocupacional(response.data.data.area_ocupacional);
            setLinha_acao(response.data.data.linha_acao);
            setAtividade_cbo(response.data.data.atividade_cbo);
            setAtividades_cbo(response.data.data.atividades_cbo);
            setAreas_atividade(response.data.data.areas_atividade);
            setVagas_minimo(response.data.data.vagas_minimo);
            setVagas_maximo(response.data.data.vagas_maximo);
        });
    }, [params.id]);
    

    function handleAtualizarClass(e: FormEvent) {
        e.preventDefault();

        api.put(`cursos/${params.id}`, {
            codigo,
            nome,
            natureza_programacao,
            tipo_programacao,
            objetivo,
            conteudo_programatico,
            area_ocupacional,
            linha_acao,
            atividade_cbo,
            atividades_cbo,
            areas_atividade,
            vagas_minimo,
            vagas_maximo,
        }).then(() => {
            alert('Atualização realizada com sucesso!');

            history.push('/');
        }).catch(() => {
            alert('Erro no cadastro!');
        })
    }

    return(
        <div id="page-teacher-form" className="container">
            <PageHeader 
                title="Cadastrar Cursos"
                description="O primeiro passo é preencher esse formulário de cadastro"
                page="lista-cursos"   
            />

            <main>
                <form onSubmit={handleAtualizarClass}>
                    <fieldset>
                        <legend>Dados</legend>
                        <Input 
                            name="codigo" 
                            label="Codigo" 
                            value={codigo}
                            onChange={(e) => { setCodigo(e.target.value) }}
                            disabled 
                            />
                        <Input 
                            name="nome" 
                            label="Nome"
                            value={nome}
                            onChange={(e) => { setNome(e.target.value) }} 
                            />
                        <Input 
                            name="natureza_programacao" 
                            label="Natureza Programação"
                            value={natureza_programacao}
                            onChange={(e) => { setNatureza_programacao(e.target.value) }} 
                            />
                        <Input 
                            name="tipo_programacao" 
                            label="Tipo Programação"
                            value={tipo_programacao}
                            onChange={(e) => { setTipo_programacao(e.target.value) }} 
                            />
                        <Input 
                            name="objetivo" 
                            label="Objetivo"
                            value={objetivo}
                            onChange={(e) => { setObjetivo(e.target.value) }} 
                            />
                        <Input 
                            name="conteudo_programatico" 
                            label="Conteudo Programatico"
                            value={conteudo_programatico}
                            onChange={(e) => { setConteudo_programatico(e.target.value) }} 
                            />
                        <Input 
                            name="area_ocupacional" 
                            label="Area Ocupacional"
                            value={area_ocupacional}
                            onChange={(e) => { setArea_ocupacional(e.target.value) }} 
                            />
                        <Input 
                            name="linha_acao" 
                            label="Linha Ação"
                            value={linha_acao}
                            onChange={(e) => { setLinha_acao(e.target.value) }} 
                            />
                        <Input 
                            name="atividade_cbo" 
                            label="Atividade CBO"
                            value={atividade_cbo}
                            onChange={(e) => { setAtividade_cbo(e.target.value) }} 
                            />
                        <Input 
                            name="atividades_cbo" 
                            label="Atividades CBO"
                            value={atividades_cbo}
                            onChange={(e) => { setAtividades_cbo(e.target.value) }} 
                            />
                        <Input 
                            name="areas_atividade" 
                            label="Areas Atividade"
                            value={areas_atividade}
                            onChange={(e) => { setAreas_atividade(e.target.value) }} 
                            />
                        <Input 
                            name="vagas_minimo" 
                            label="Vagas Minimo"
                            value={vagas_minimo}
                            onChange={(e) => { setVagas_minimo(e.target.value) }}  
                            />
                        <Input 
                            name="vagas_maximo" 
                            label="Vagas Maximo"
                            value={vagas_maximo}
                            onChange={(e) => { setVagas_maximo(e.target.value) }}  
                            />
                    </fieldset>

                    <footer>
                        <p>
                            <img src={warningIcon} alt="Aviso importante" />
                            Importante! <br />
                            Preencha todos os dados
                        </p>
                        <button type="submit">
                            Alterar o cadastro
                        </button>
                    </footer>
                </form>
            </main>
        </div>
    );
}

export default CursoAtualizarForm;