import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from "../../services/api";

import PageHeader from '../../components/PageReader';


import './styles.css';

export interface Curso {
    id: number,
    codigo: number,
    nome: string;
    natureza_programacao: string;
    tipo_programacao: string; 
    objetivo: string;
    conteudo_programatico: string; 
    vagas_minimo: number;
    vagas_maximo: number
}


function CursoList(){
    const history = useHistory();
    const [cursos, setCursos] = useState([]);

    useEffect(() => {
        api
          .get("/cursos")
          .then((response) => setCursos(response.data.data))
          .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
          });
      }, []);

      function deletarCurso(id:number){

        api.delete(`cursos/${id}`, {
        }).then(() => {
            alert('Exclusão realizada com sucesso!');

            history.push('/');
        }).catch(() => {
            alert('Erro no exclusão!');
        })

      }

    return(
        <div id="page-teacher-list" className="container">
            <PageHeader title="Estes são os cursos disponíveis." page=""
                description={"O total de registros é: "+cursos.length}
            /> 

            <main>
                <table id='employee'>
                    <tr>
                        <th>ID</th>
                        <th>Codigo</th>
                        <th>Nome</th>
                        <th>Natureza</th>
                        <th>Tipo Programação</th>
                        <th>Excluir</th>
                        <th>Atualizar</th>
                    </tr>
                
                {cursos.map((curso: Curso )=> {
                    return (
                        <tr key={curso.id}>
                            <td>{ curso.id }</td>
                            <td>{ curso.codigo }</td>
                            <td>{ curso.nome }</td>
                            <td>{ curso.natureza_programacao }</td>
                            <td>{ curso.tipo_programacao }</td>
                            <td><button className='button' onClick={() => deletarCurso(curso.id)}>Excluir</button></td>
                            <td><a className='a' href={`/cursos/${curso.id}`}>Atualizar</a></td>
                        </tr>
                        );
                     })}
                </table>
            </main>
        </div>
    );
}

export default CursoList;