import React from 'react';
import { Link } from 'react-router-dom';

import landingImg from '../../assets/images/landing.svg';

import studyIcon from '../../assets/images/icons/study.svg';
import giveClassesIcon from '../../assets/images/icons/give-classes.svg';

import './styles.css';

function Landing(){
    return (
        <div id="page-landing">
            <div id="page-landing-content" className="container">
                <div className="logo-container">
                    <h2>Sua Plataforma de Cursos Senar-MT.</h2>
                </div>

                <img 
                    src={landingImg} 
                    alt="Plataforma de estudos" 
                    className="hero-image" 
                />

                <div className="buttons-container">
                    <Link to="/lista-cursos" className="study">
                        <img src={studyIcon} alt="Estudar"/>
                        Lista de Cursos
                    </Link>

                    <Link to="/cadastro" className="give-classes">
                        <img src={giveClassesIcon} alt="Dar aulas"/>
                        Cadastrar Curso
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Landing;