/*import axios from 'axios';

const api = axios.create({
    baseURL: 'http://localhost:3333',
});

export default api;

*/

import axios from "axios";

const token = "ckRicMTVG3vMcKwCQ0vbz4PSIKqmdsgfFP5aujU7";

const api = axios.create({
  baseURL: "https://prova.senarmt.org.br/api/v1",
  headers: {
    "Access-Control-Allow_Origin": "*",
    "Content-type": "Application/json",
    Authorization: `Bearer ${token}`
  },
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 302; // default
  }
});

export default api;